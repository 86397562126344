/* eslint-disable prettier/prettier */
import { FixedNumber } from '@ethersproject/bignumber'
import { walletStore } from '@/stores/wallet-store'
import moment from 'moment'
import Web3 from 'web3'
import { WBNBContract } from './bsc-helper'
import { priceHelper } from './price-helper'
import { blockchainHandler } from '@/blockchain'
import { promiseHelper } from './promise-helper'

const BSC_BLOCK_TIME = 3
const BLOCKS_PER_YEAR = FixedNumber.from(`${(60 / BSC_BLOCK_TIME) * 60 * 24 * 365}`) // 10512000

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', error => reject(error))
  })
}

const bscMainWeb3 = blockchainHandler.getWeb3(56)!

export class FarmHandler {
  farmContract: any
  oldFarmContract: any
  LPTokenContract: any
  tokenContract: any
  lockDuration?: moment.Duration

  rewardToken?: string
  poolToken?: string

  constructor() {
    this.farmContract = new bscMainWeb3.eth.Contract(require('./farm.abi.v2.json'), process.env.VUE_APP_FARM_2_ADDRESS)
    this.oldFarmContract = new bscMainWeb3.eth.Contract(
      require('./farm.abi.json'),
      '0x03365085EF9A639F2bA0556fD4a961f10383fc57'
    )
  }

  async load() {
    const rewardToken = await this.farmContract.methods.rewardToken().call()
    this.rewardToken = Web3.utils.toChecksumAddress(rewardToken)
    const {
      0: poolToken,
      1: allocPoint,
      2: lastRewardBlock,
      3: accRewardPerShare,
      4: lockDuration
    } = await this.farmContract.methods.poolInfo(0).call()
    this.poolToken = Web3.utils.toChecksumAddress(poolToken)
    this.lockDuration = moment.duration(lockDuration, 'seconds')

    this.tokenContract = new bscMainWeb3.eth.Contract(require('./erc20.abi.json'), this.rewardToken)
    this.LPTokenContract = new bscMainWeb3.eth.Contract(require('./erc20.abi.json'), this.poolToken)
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.farmContract = new web3.eth.Contract(require('./farm.abi.v2.json'), process.env.VUE_APP_FARM_2_ADDRESS)
      this.oldFarmContract = new web3.eth.Contract(
        require('./farm.abi.json'),
        '0x03365085EF9A639F2bA0556fD4a961f10383fc57'
      )
      this.tokenContract = new web3.eth.Contract(require('./erc20.abi.json'), this.rewardToken)
      this.LPTokenContract = new web3.eth.Contract(require('./erc20.abi.json'), this.poolToken)
    }
  }

  async approved(account, index = 3) {
    try {
      const allowance = await this.LPTokenContract.methods.allowance(account, process.env.VUE_APP_FARM_2_ADDRESS).call()
      return !!+Web3.utils.fromWei(allowance)
    } catch (error) {
      if (index) {
        await promiseHelper.delay(200)
        return await this.approved(account, --index)
      }
      console.error(error)
      return false
    }
  }

  async approve(account) {
    const f = this.LPTokenContract.methods.approve(
      process.env.VUE_APP_FARM_2_ADDRESS,
      Web3.utils.toWei(`${2 ** 32 - 1}`)
    )
    await sendRequest(f, account)
  }
  async stakeLP(account, amount) {
    const f = this.farmContract.methods.stakeLP(0, Web3.utils.toWei(`${amount.toString()}`))
    await sendRequest(f, account)
  }
  async unstakeLP(account, amount) {
    const f = this.farmContract.methods.unstakeLP(0, Web3.utils.toWei(`${amount.toString()}`))
    await sendRequest(f, account)
  }
  async getRewardAmount(account) {
    const amount = await this.farmContract.methods.getRewardAmount(0, account).call()
    return FixedNumber.from(`${Web3.utils.fromWei(amount)}`)
  }
  async getUserInfo(account) {
    const { 0: amount, 1: lastStakeTime } = await this.farmContract.methods.getUserInfo(0, account).call()
    return {
      amount: FixedNumber.from(`${Web3.utils.fromWei(amount)}`),
      lastStakeTime: +lastStakeTime ? moment(+lastStakeTime * 1000) : null
    }
  }
  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods.balanceOf(account).call()
    return FixedNumber.from(`${Web3.utils.fromWei(allowance)}`)
  }
  async getFarmLPBalance() {
    const allowance = await this.LPTokenContract.methods.balanceOf(process.env.VUE_APP_FARM_2_ADDRESS).call()
    return FixedNumber.from(`${Web3.utils.fromWei(allowance)}`)
  }
  async harvest(account) {
    const f = this.farmContract.methods.harvest(0)
    await sendRequest(f, account)
  }
  async getFarmLiquidityAndApy() {
    const bnb2UsdPrice = FixedNumber.from(await priceHelper.bnb2Usd())

    const bnbBalanceLP = await WBNBContract.methods
      .balanceOf(this.poolToken)
      .call()
      .then(x => FixedNumber.from(`${Web3.utils.fromWei(x)}`))
    const tokenBalanceLP = await this.tokenContract.methods
      .balanceOf(this.poolToken)
      .call()
      .then(x => FixedNumber.from(`${Web3.utils.fromWei(x)}`))
    const totalSupplyLP: FixedNumber = await this.LPTokenContract.methods
      .totalSupply()
      .call()
      .then(x => FixedNumber.from(`${Web3.utils.fromWei(x)}`))

    const tokenPerBlock = await this.farmContract.methods
      .tokenPerBlock()
      .call()
      .then(x => FixedNumber.from(`${Web3.utils.fromWei(x)}`))
    const poolInfo = await this.farmContract.methods.poolInfo(0).call()
    const farmLPBalance = FixedNumber.from(`${Web3.utils.fromWei(poolInfo[5])}`)

    const totalLPInUsd: FixedNumber = bnbBalanceLP
      .mulUnsafe(FixedNumber.from('2')) // total = x2 bnb
      .mulUnsafe(bnb2UsdPrice) // convert to usd

    const lp2UsdPrice = totalLPInUsd.divUnsafe(totalSupplyLP)
    const poolLiquidityUsd = farmLPBalance.mulUnsafe(lp2UsdPrice) // => bnb in contract

    const token2BnbPrice = bnbBalanceLP.divUnsafe(tokenBalanceLP)
    const token2UsdPrice = token2BnbPrice.mulUnsafe(bnb2UsdPrice)

    const apy = poolLiquidityUsd.isZero()
      ? FixedNumber.from('0')
      : tokenPerBlock
          .mulUnsafe(BLOCKS_PER_YEAR)
          .mulUnsafe(token2UsdPrice)
          .divUnsafe(poolLiquidityUsd)
          .mulUnsafe(FixedNumber.from('100'))
    return { poolLiquidityUsd, apy, lp2UsdPrice, token2UsdPrice }
  }

  async unstakeV1(account) {
    const uInfo = await this.oldFarmContract.methods.userInfo(0, account).call()
    const f = this.oldFarmContract.methods.withdraw(0, uInfo[0], true)
    await sendRequest(f, account)
  }

  async getOldStaked(account) {
    const res = await this.oldFarmContract.methods.userInfo(0, account).call()
    return FixedNumber.from(Web3.utils.fromWei(res[0]))
  }
}

const farmContract = new bscMainWeb3.eth.Contract(require('./farm.abi.json'), process.env.VUE_APP_FARM_2_ADDRESS)

export const farmHandler = {
  getStakedAmount: async () => {
    try {
      const res = await farmContract.methods.userInfo(1, walletStore.account).call()
      return FixedNumber.from(bscMainWeb3.utils.fromWei(res[0]))
    } catch (error) {
      console.error(error)
      return FixedNumber.from('0')
    }
  },
  getLPLocked: async () => {
    try {
      const res = await farmContract.methods.userInfo(0, walletStore.account).call()
      return FixedNumber.from(bscMainWeb3.utils.fromWei(res[0]))
    } catch (error) {
      console.error(error)
      return FixedNumber.from('0')
    }
  }
}
